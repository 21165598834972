import React, { useEffect, useState } from 'react'
import { Section } from '../components'
import api from '../api-client'
import { useParams } from 'react-router-dom'
import { Spinner } from '@fluentui/react'
import { dollars, FUND_RELEASE_LOAN_HELP_LINK, getFundReleaseDetails } from '@oneethos/shared'
import { getBrowserFingerprintData } from '../browser-fingerprint'
import { toast } from "react-toastify"
import { useLocation } from 'react-router-dom'
import { errorToString } from '@oneethos/shared'

export const FundingReleasePage = () => {
  const [pendingAgreement, setPendingAgreement] = useState(null)
  const [agreed, setAgreed] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [isExpired, setIsExpired] = useState<boolean>(false)

  const location = useLocation()
  const { id } = useParams()
  const token = new URLSearchParams(location.search).get('t')
  const helpLink: string = FUND_RELEASE_LOAN_HELP_LINK
  const details = pendingAgreement ? getFundReleaseDetails(pendingAgreement.type, pendingAgreement.companyName ) : { description: '', requirements: [] }
  const { description, requirements } = details

  const fetchFundReleaseInfo = async () => {
    return api.get(`/c/loanapps/${id}/fund-release?t=${encodeURIComponent(token)}`)
      .then((data) => {
        setPendingAgreement(data)
      }).catch((ex) => {
        if (ex.status === 401 || ex.message?.includes('expired')) {
          setIsExpired(true)
        }
      })
  }

  useEffect(() => {
    if (id) {
      fetchFundReleaseInfo()
    }
  }, [id])

  const handleAgree = () => {
    if (pendingAgreement) {
      setAgreed(!agreed)
    }
  }

  const handleApprove = async () => {
    try {
      setLoading(true)
      await api.post(
        `/c/loanapps/${id}/fund-release/${pendingAgreement._id}/approve${token ? `?t=${encodeURIComponent(token)}` : ''}`,
        { browserInfo: getBrowserFingerprintData() }
      )
      setIsSubmitted(true)
    } catch (err) {
      toast.error(errorToString(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Section>
      { isExpired ? 
        <div className="text-center">
          <h3>Link Expired</h3>
          <p>
            This approval link has expired. Please contact your installer to send 
            you a new funding release approval request if needed.
          </p>
        </div> : 
      isSubmitted ? (
        <div className="text-center">
          <h3>Thank you!</h3>
          <p>
            Your approval has been documented. Once your installer has provided all 
            necessary documentation for this milestone, we will release the approved 
            funds to them.
          </p>
        </div>
      ) : pendingAgreement ? (
        <>
          <h3 className='text-center'>Fund Release Request</h3>
          <div className="mb-4">
            <p><strong>Amount:</strong> {dollars(pendingAgreement.amount)}</p>
            <p>
              {description}
            </p>
          </div>

          <div className="mb-4">
            <p><strong>By approving this request, you certify the following:</strong></p>
            <ul className="mb-4">
              {requirements.map((req, index) => (
                <li key={index} className="mb-1">
                  <strong>{req.title}</strong> {req.detail}
                </li>
              ))}
            </ul>
          </div>

          <p>
            <strong>Help Article:</strong><br />
            <a href={helpLink} target="_blank" rel="noopener noreferrer" className='mt-3'>
              Learn more about the fund release process and what it means for your project
            </a>
          </p>

          <div className="form-group text-center mt-4">
            <label className='d-flex align-items-center justify-content-center gap-2'>
              <input 
                type="checkbox"
                checked={agreed}
                onChange={handleAgree}
              />
              <b>I approve the release of funds to {pendingAgreement.companyName}.</b>
            </label>
          </div>

          <div className='text-center mt-3'>
            <button 
              className="btn btn-primary" 
              disabled={!agreed} 
              onClick={handleApprove}
            >
              {loading ? <Spinner /> : 'Approve Request'}
            </button>
          </div>
        </>
      ) : (
        <p>No pending fund releases found.</p>
      )}
    </Section>
  )
}

export default FundingReleasePage
