import React, { useReducer, FC, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import './public-symlink/css/installers-app.webflow.css'
import './App.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import appReducer from './reducers'
import actions from './actions'
import { AppContext } from './appContext'
import { initializeIcons } from '@fluentui/react/lib/Icons'
import Telemetry from './components/telemetry'
import api from './api-client'
import router from './router'
import { useLocalStorage } from './hooks'
import { StorageKey } from './lib'
import { errorToString } from '@oneethos/shared'
import { clarity } from './lib/clarity'
import * as Sentry from "@sentry/react"
import ReactGA from "react-ga4"

Sentry.init({
  // appropriate to hardcode the dsn for tracing across all environments
  dsn: "https://6fb873434ad34d053094639252442bf0@o4508212757463040.ingest.us.sentry.io/4508212760281088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: !!process.env.REACT_APP_ENV_NAME,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% 
  // while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1, 
  // If you're not already sampling the entire session, change the 
  // sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV_NAME || 'dev'
})

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
}

export const App: FC = () => {
  // useLocalStorage hook syncronizes app state if installer 
  // logs in or out across separate tabs
  const [token, , clearToken] = useLocalStorage(StorageKey.InstallerToken, undefined)
  const [, , clearImpersonate] = useLocalStorage(StorageKey.ImpersonateUserId, undefined)
  const [appState, dispatch] = useReducer(appReducer, {
    tenant: {
      loading: true,
      config: undefined,
      error: undefined
    },
    registration: {
      token,
      loading: !!token,
      installer: undefined
    },
    installersStatusConfig: {
      loading: true
    }
  })

  useEffect(() => {
    if (process.env.REACT_APP_CLARITY_KEY) {
      clarity.init(process.env.REACT_APP_CLARITY_KEY)
    }

    initializeIcons()

    api.get('/tenant').then(tenant => {
      dispatch(actions.setTenant(tenant))
    }).catch(ex => {
      dispatch(actions.setTenantError(errorToString(ex)))
    })

    const params = new URLSearchParams(window.location.search)
    const search = Object.fromEntries(params)
    for (const param of ['utm_campaign', 'utm_medium', 'utm_source']) {
      if (search[param]) {
        localStorage.setItem(param, search[param])
      }
    }
  }, [])

  useEffect(() => {
    if (!localStorage.getItem(StorageKey.DeviceId)) {
      // initialize immediately so it can be used during API requests 
      const uuid = crypto.randomUUID()
      localStorage.setItem(StorageKey.DeviceId, uuid)
    }

    if (token) {
      api.get('/installers/me').then(installer => {
        dispatch(actions.setInstaller(installer))
      }).catch(ex => {
        if (ex.code === 'UNKNOWN_DEVICE') {
          clearToken()
        }

        clearImpersonate()
        dispatch(actions.setAuthError(ex.code || ex.error || ex.message))
      })

      api.get('/installers-status').then(res => {
        dispatch(actions.setInstallersStatusConfig(res))
      }).catch(ex => {
        dispatch(actions.setInstallersStatusConfigError(ex.error))
      })
    }
  }, [token, clearToken, clearImpersonate])

  return (
    <>
      {/* <ThemeProvider applyTo="body" theme={DarkTheme}> */}
      <AppContext.Provider value={{ state: appState, dispatch }}>
        <Telemetry>
          <RouterProvider router={router} />
        </Telemetry>
      </AppContext.Provider>
      <ToastContainer />
      {/* // </ThemeProvider> */}
    </>
  )
}
