import { useState, useEffect, useCallback } from "react"
import { AccessLevel, Installer } from "@oneethos/shared"
import { InstallerAdminRow, NewInstallerForm, Section } from "../components"
import { useAppState } from "../hooks"
import { Navigate, useParams } from "react-router-dom"
import { toast } from 'react-toastify'
import api from '../api-client'
import { OrgAdmin } from "../components"

export const UsersAdmin = () => {
  const [error, setError] = useState('')
  const [installers, setInstallers] = useState([])
  const params = useParams()
  const { registration: { installer } } = useAppState()

  const installerID = params['installerID'] || installer.installerID

  const fetchInstallers = useCallback(() => {
    const search = new URLSearchParams({ installerID })

    const url = `/installers?${search.toString()}`
    api.get(url).then(_installers => {
      setInstallers(_installers)
    }).catch(ex => { setError(ex.error || ex.message) })
  }, [installerID])

  const patch = useCallback((i: Installer) => {
    api.patch(`/installers/${i._id}`, i)
      .then(() => {
        toast.success('Installer update saved', { autoClose: 1000 })
        fetchInstallers()
      })
      .catch(ex => { setError(ex.error || ex.message) })
  }, [fetchInstallers])

  useEffect(() => {
    if (installer?.isAdmin) {
      fetchInstallers()
    }
  }, [fetchInstallers, installer?.isAdmin])

  if (!installer?.isAdmin) {
    return <Navigate to="/projects" />
  }

  const handleDeleteInstaller = (installer: Installer) => { 
    setInstallers(installers.filter(i => i._id !== installer._id))
  }

  return <div className="installer-admin-page">
    <Section>
      <div className="section-title">
        <h3 className="mb-0">Users</h3>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </div>

      <div className="table-list-wrap">
        <table className="table">
          <tbody>
            <tr>
              <th>Email</th>
              <th>Access Level</th>
              <th>Status</th>
              <th>Active</th>
              {installer ?<th></th> : null}
            </tr>
            {installers.map(i => <InstallerAdminRow
              self={installer}
              key={i._id}
              installer={i}
              onChangeAccess={access => patch({ ...i, access })}
              onToggleAccess={() => patch({ ...i, active: !i.active })}
              onDelete={() => handleDeleteInstaller(i)}
            />)}
          </tbody>
        </table>
      </div>
    </Section>
    <Section>
      <NewInstallerForm
        onAdd={() => fetchInstallers()}
        installerID={installerID}
      />
    </Section>
  </div>
}

export const InstallerAdminPage = () => {
  const params = useParams()
  const { registration: { installer } } = useAppState()
  const installerID = params['installerID'] || installer.installerID

  if (!installer?.isAdmin) {
    return <Navigate to="/projects" />
  }

  const isIntegrator = installer.access === AccessLevel.Integrator

  const hideUsers = (
    isIntegrator &&
    installerID !== installer.installerID
  )

  return <div className="installer-admin-page">
    <Section>
      <h2 className="mb-0">{installerID} | Admin</h2>
    </Section>

    {hideUsers ? null : <UsersAdmin />}

    {/* 
      if integrator, user presumably only got here thru the list of orgs they can access,
      so we don't need excessive security checks at this point; security is enforced 
      via api regardless
    */}
    {(
      installer.isSupport ||
      installer.isIntegrator ||
      installer.isAdmin
    ) ? <OrgAdmin installerID={installerID} /> : null}
  </div>
}

export default InstallerAdminPage