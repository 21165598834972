import { useEffect, useState } from "react"
import api from '../api-client'
import { errorToString } from "@oneethos/shared"

export const useApi = <T>(path: string, deps = []): { 
  fetching: boolean,
  error: string | { code: string, message: string, status: number },
  data: T
} => {
  const [fetching, setFetching] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState<string | {
    code: string,
    message: string,
    status: number
  }>(null)

  useEffect(() => {
    if (path) {
      let ignore = false
      api.get(path).then(json => {
        if (!ignore) {
          setData(json)
        }
      }).catch(ex => {
        if (ex.code) {
          setError(ex)
        } else {
          setError(errorToString(ex))
        }
      }).finally(() => setFetching(false))

      return () => {
        ignore = true
      }
    }
  }, [path, ...deps])

  return { fetching, error, data }
}