import { monthsSince, yyyymmdd } from "./date-utils"
import { mask } from "./masking"

export type EqCoded<T> = {
  code: T
  description: string
}

export type EquifaxScoreModel = {
  modelNumber: string
  rejects?: { code: string }[]
  score?: number
  reasons?: EqCoded<string>[]
  scoreNumberOrMarketMaxIndustryCode?: { code: string }
}

export enum CreditorClassificationCode {
  Retail = "01",
  Medical = "02",
  OilCompany = "03",
  Government = "04",
  PersonalServices = "05",
  Insurance = "06",
  Educational = "07",
  Banking = "08",
  RentalOrLeasing = "09",
  Utilities = "10",
  CableOrCellular = "11",
  Financial = "12",
  CreditUnion = "13",
  Automotive = "14",
  CheckGuarantee = "15"
}

export type BaseEquifaxCollection = {
  industryCode: string
  customerNumber: string
  clientNameOrNumber: string
  statusCode: EqCoded<string>
  indicator: string
  originalAmount: number
  balance: number
  accountDesignatorCode: EqCoded<string>
  creditorClassificationCode: EqCoded<CreditorClassificationCode>
}

export type RawEquifaxCollection = BaseEquifaxCollection & {
  dateReported: string
  dateAssigned: string
  statusDate: string
  dateOfFirstDelinquency: string
}

export type EquifaxCollection = BaseEquifaxCollection & {
  dateReported: Date
  dateAssigned: Date
  statusDate: Date
  dateOfFirstDelinquency: Date
}

export type BaseEquifaxBankruptcy = {
  customerNumber: string // "999VF00740"
  type: string // "I"
  filer: string // "J"
  industryCode: string // "VF"
  currentIntentOrDispositionCode: EqCoded<string>
  priorIntentOrDispositionCode: EqCoded<string>
}

export type RawEquifaxBankruptcy = BaseEquifaxBankruptcy & {
  dateFiled?: string // "02002018"
  dispositionDate?: string
  dateReported?: string
}

export type EquifaxBankruptcy = BaseEquifaxBankruptcy & {
  dateFiled?: Date // "02002018"
  dispositionDate?: Date
  dateReported?: Date
}

export type BaseEquifaxTrade = {
  customerNumber: string // "999BB44497",
  automatedUpdateIndicator: string // "*",
  monthsReviewed: string // "68",
  accountDesignator: EqCoded<string>
  accountNumber: string // "6490383110822",
  thirtyDayCounter: number // 1,
  sixtyDayCounter: number // 1,
  ninetyDayCounter: number // 5,
  previousHighRate1: number // 5,
  previousHighDate1: string // "052019",
  previousHighRate2: number // 5,
  previousHighDate2: string // "042019",
  previousHighRate3: number // 5,
  previousHighDate3: string // "032019",
  customerName: string // "EQUIFAX TEST DATA",
  highCredit: number // 5737,
  creditLimit: number // 4900,
  balance: number // 0,
  pastDueAmount?: number
  portfolioTypeCode: EqCoded<string>
  rate: EqCoded<number>
  narrativeCodes: EqCoded<string>[]
  rawNarrativeCodes: string[] // [ "DB" ],
  accountTypeCode: EqCoded<string>
  activityDesignatorCode: EqCoded<string>
  scheduledPaymentAmount: number
  purchasedFromOrSoldCreditorIndicator: EqCoded<string>
  termsFrequencyCode: EqCoded<string>
  paymentHistory1to24: EqCoded<string>[]
  "24MonthPaymentHistory": EqCoded<string>[] // deprecated
  // NOTE: almost certainly incomplete
}

export type RawEquifaxTrade = BaseEquifaxTrade & {
  dateReported?: string // "06002019",
  dateOpened?: string // "10002013",
  closedDate?: string // "09002020",
  lastActivityDate?: string // "092018"
  lastPaymentDate?: string // "05002019",
}

export type EquifaxTrade = BaseEquifaxTrade & {
  dateReported?: Date // "06002019",
  dateOpened?: Date // "10002013",
  closedDate?: Date // "09002020",
  lastActivityDate?: Date // "092018"
  lastPaymentDate?: Date // "05002019",
  dateMajorDelinquencyFirstReported?: Date
  // calculated oneethos fields
  isSharedWithCoapplicant?: boolean
}

export type RawEquifaxPrequalReport = {
  models: EquifaxScoreModel[]
  birthDate: string
  identification: {
    subjectSocialNum: string
    inquirySocialNum?: string
  }
  subjectName: {
    firstName: string
    middleName: string
    lastName: string
  }
  subjectSocialNum?: string
  trades: RawEquifaxTrade[]
  bankruptcies?: RawEquifaxBankruptcy[]
  collections?: any[]
  addresses?: any[]
  inquiries?: any[]
}

const EqDates = {
  datesIfExist(
    o: RawEquifaxBankruptcy | RawEquifaxCollection,
    fields: string[]
  ): Partial<EquifaxBankruptcy> {
    const obj = {}

    fields.forEach(f => o[f] ? obj[f] = this.toDate(o[f]) : null)

    return obj
  },

  toDate(s: string): Date {
    let iso
    if (s.length === 8) {
      const month = s.substring(0, 2)
      let day = s.substring(2, 4)
      const year = s.substring(4)

      day = day === '00' ? '01' : day
      iso = `${year}-${month}-${day}T12:00:00.000-00:00`
    } else if (s.length === 4) {
      const month = s.substring(0, 2)
      const day = '01'
      const year = `20${s.substring(2)}`

      iso = `${year}-${month}-${day}T12:00:00.000-00:00`
    } else if (s.length === 6) {
      const month = s.substring(0, 2)
      const day = '01'
      const year = s.substring(2)

      iso = `${year}-${month}-${day}T12:00:00.000-00:00`
    } else if (new Date(s).toString() !== 'Invalid Date') {
      iso = s
    } else {
      throw `unknown date format "${s}"`
    }

    const date = new Date(iso)

    if (date.toString() === 'Invalid Date') {
      throw `Cannot parse date from "${s}"`
    }

    return date
  }
}

export type PastDueSpec = {
  since?: 12 | 24 | 36   // months
  none?: boolean
  days?: 30 | 60 | 90
  max?: number
}

type ThirtySixtyNinetySummary = {
  30: number
  60: number
  90: number
}

type LatePaySummary = {
  [key: string]: number
}

export type CriteriaSpec = {
  before?: Date
  since?: Date
  none?: boolean
  only?: CreditorClassificationCode
  // max?: number
}

export type DecisionCriteria = {
  minScore?: number
  debtToIncome?: number
  bankruptcy?: CriteriaSpec
  collections?: CriteriaSpec
  pastDue?: PastDueSpec
}

export type CriteriaEvaluationResultOption = 'pass' | 'fail'
export type PrequalResultOption = CriteriaEvaluationResultOption | 'no-hit'

export type CriteriaEvaluation = {
  result: CriteriaEvaluationResultOption
  criteria: DecisionCriteria
  reasons: string[]
}

export type CriteriaEvaluationResult = {
  currentDti: number
  postDti: number
  estimatedPayment: number
  statedGrossMonthlyIncome: number
  result: CriteriaEvaluationResultOption
  byCriteria: CriteriaEvaluation[]
}

export class EquifaxPrequalReport {
  id: string
  hitCode: EqCoded<string>
  models: EquifaxScoreModel[]
  identification?: {
    subjectSocialNum: string
    inquirySocialNum?: string
  }
  birthDate?: string
  subjectName: {
    firstName: string
    middleName: string
    lastName: string
  }
  subjectSocialNum?: string
  trades: EquifaxTrade[]
  bankruptcies?: EquifaxBankruptcy[]
  collections?: EquifaxCollection[]
  addresses?: any[]
  inquiries?: any[]

  // calculated fields
  combinedMonthlyDebt?: number
  monthlyDebt?: number
  hasLatePayment12?: boolean // deprecated but exists in legacy records
  hasLatePayment24?: boolean // deprecated but exists in legacy records
  hasLatePayment?: boolean
  latePaymentSummary?: LatePaySummary
  thirtySixtyNinetySummary?: ThirtySixtyNinetySummary
  createdDate?: Date

  constructor(o: RawEquifaxPrequalReport) {
    const { bankruptcies, collections, trades, ...props } = o

    this.bankruptcies = this.initList(
      bankruptcies,
      ['dateFiled', 'dateReported', 'dispositionDate']
    )

    this.collections = this.initList(
      collections,
      ['dateReported', 'dateAssigned', 'statusDate', 'dateOfFirstDelinquency']
    )

    this.trades = this.initList(
      trades,
      [
        'dateReported',
        'dateOpened',
        'lastPaymentDate',
        'lastActivityDate',
        'closedDate',
        'dateMajorDelinquencyFirstReported'
      ]
    )

    Object.keys(props).forEach(k => this[k] = props[k])

    // after thoroughly reviewing the trades, it seems most straightforward to rely 
    // on the scheduledPaymentAmount; most other accounts that have issues (trade.rate.code !== 1)
    // are reflected elsewhere in the report. According to docs this should also reflect monthly
    // payments even when the terms are biweekly or other. It also generally appears to 
    // ignore accounts that have been closed/written off or deferred, so generally appears reliable.
    this.monthlyDebt = this.trades?.reduce((prev, trade) => {
      if (trade.balance === 0) {
        return prev
      }

      return prev + (trade.scheduledPaymentAmount || 0)
    }, 0)

    this.thirtySixtyNinetySummary = this.count306090s()
    this.latePaymentSummary = this.countLatePayments(24)
  }

  public isNoHit(): boolean {
    return this.hitCode?.description === 'No-Hit' || this.hitCode?.code === "2"
  }

  public desensitize(): void {
    this.deleteSubjectSocialNum()
    this.maskAccounts()
    this.birthDate = '[deleted]'
  }

  private maskAccounts(): void {
    const showDigits = (s: string) => {
      switch (true) {
        case s.length > 8: return 4
        case s.length <= 7 && s.length > 5: return 3
        case s.length <= 5: return 2
      }
    }

    this.trades = this.trades.map(t => {
      t.accountNumber = mask(t.accountNumber, showDigits(t.accountNumber))
      t.customerNumber = mask(t.customerNumber, showDigits(t.customerNumber))
      return t
    })
  }

  private deleteSubjectSocialNum(): void {
    if (this.identification?.subjectSocialNum) {
      this.identification.subjectSocialNum = '[deleted]'
    }
    if (this.identification?.inquirySocialNum) {
      this.identification.inquirySocialNum = '[deleted]'
    }
    if (this.subjectSocialNum) {
      this.subjectSocialNum = '[deleted]'
    }
  }

  public getMaxScore(): EquifaxScoreModel | undefined {
    return this.models?.reduce((prev, curr) => {
      if (!prev) {
        return curr
      }

      if (prev.score && curr.score && curr.score > prev.score) {
        return curr
      }

      return prev
    }, undefined)
  }

  public evaluateCriteria(
    statedIncome: number,
    estimatedPayment: number,
    criteria: DecisionCriteria[]
  ): CriteriaEvaluationResult {
    const results = []
    // if combinedMonthlyDebt is set it means we're doing a combined evaluation
    const debt = this.combinedMonthlyDebt || this.monthlyDebt
    const currentDti = (debt / (statedIncome / 12)) * 100
    const postDti = ((debt + estimatedPayment) / (statedIncome / 12)) * 100

    for (const i in criteria) {
      const c = criteria[i]
      const reasons = Object.keys(c).map(key => {
        if (key === 'minScore') {
          if (!this.models?.length) {
            return `[${i}] score not found for model`
          }

          for (const m of this.models) {
            if (m.score === undefined || m.score < c.minScore) {
              return `[${i}] score of ${m.score} is less than ${c.minScore}`
            }
          }
        }

        if (key === 'debtToIncome') {
          if (postDti > c.debtToIncome) {
            return `[${i}] dti of ${postDti.toFixed(1)}% exceeds ${c.debtToIncome}%`
          }
        }

        if (key === 'bankruptcy') {
          for (const b of this.bankruptcies) {
            if (c.bankruptcy.none) {
              return `[${i}] bankruptcy exists`
            } else if (c.bankruptcy.before) {
              if (b.dateFiled > c.bankruptcy.before) {
                return [
                  `[${i}] bankruptcy filed ${yyyymmdd(b.dateFiled)}]`,
                  `which is after ${yyyymmdd(c.bankruptcy.before)}`
                ].join(' ')
              }
            } else if (c.bankruptcy.since) { // does this make sense? 
              if (b.dateFiled < c.bankruptcy.since) {
                return [
                  `[${i}] bankruptcy filed ${yyyymmdd(b.dateFiled)}`,
                  `which is before ${yyyymmdd(c.bankruptcy.since)}`
                ].join(' ')
              }
            }
          }
        }

        if (key === 'collections') {
          return this.evalCollections(c.collections, i)
        }

        if (key === 'pastDue') {
          return this.evalPastDue(c.pastDue, i)
        }
      }).filter(r => r)

      if (reasons.length) {
        results.push({
          result: 'fail',
          criteria: c,
          reasons
        })
      } else {
        results.push({
          result: 'pass',
          criteria: c,
          reasons
        })
      }
    }

    return {
      currentDti,
      postDti,
      estimatedPayment,
      statedGrossMonthlyIncome: statedIncome / 12,
      result: results.some(r => r.result === 'pass') ? 'pass' : 'fail',
      byCriteria: results
    }
  }

  private initList(list: any[], dateFields: string[]) {
    return list?.map(item => {
      return {
        ...item,
        ...EqDates.datesIfExist(item, dateFields)
      }
    }) || []
  }

  public hasBankruptcySince(d: Date): boolean {
    for (const b of this.bankruptcies || []) {
      if (b.dateFiled > d) {
        return true
      }
    }

    return false
  }

  public hasOnlyCollectionsSince(since: Date, code?: CreditorClassificationCode): boolean {
    for (const c of this.collections) {
      // there are other medical related fields in the api spec, but this appears 
      // like the only thing that needs checked for collections
      if (c.dateOfFirstDelinquency > since) {
        if (code && code !== c.creditorClassificationCode?.code) {
          return false
        }
      }
    }

    return true
  }

  public evalCollections(spec: CriteriaSpec, i: string): string {
    // Apparently the collections array doesn't always have collections in them even if 
    // the trades do, we need to check both the array and the trades
    const { none, only, before, since } = spec

    // first the collections
    for (const coll of this.collections) {
      if (none) {
        return `[${i}] collection exists`
      } else if (before) {
        if (coll.dateOfFirstDelinquency > before) {
          let msg = [
            `[${i}] collection ${yyyymmdd(coll.dateOfFirstDelinquency)}`,
            `which is after ${yyyymmdd(before)}`
          ].join(' ')

          if (only && only !== coll.creditorClassificationCode?.code) {
            msg = `${msg} and ${coll.creditorClassificationCode?.code} does not match exception for ${only}`
          }

          return msg
        }
      } else if (since) { // does this make sense? 
        if (coll.dateOfFirstDelinquency < since) {
          return `[${i}] collection ${yyyymmdd(coll.dateOfFirstDelinquency)}` +
            ` which is before ${yyyymmdd(since)}`
        }
      }
    }

    const collectionCodes = [
      'DB', // CHARGED OFF ACCOUNT
      'CZ', // COLLECTION
    ]

    // then the trades
    const isCollectionTrade = (t: EquifaxTrade) => {
      return t.rawNarrativeCodes?.some(c => collectionCodes.includes(c))
    }

    let collectionTrades = this.trades.filter(isCollectionTrade)
    if (only) {
      collectionTrades = collectionTrades.filter(t => t.accountDesignator.code !== only)
    }

    if (!collectionTrades.length) {
      // no issues
      return
    }

    if (none && collectionTrades.length) {
      return `[${i}] collection exists`
    }

    if (before) {
      for (const t of collectionTrades) {
        const collectionDate = t.dateMajorDelinquencyFirstReported || t.closedDate
        if (collectionDate > before) {
          return `[${i}] collection ${yyyymmdd(collectionDate)} which is after ${yyyymmdd(before)}`
        }
      }
    }
  }

  // return value of string means it doesn't pass
  // NOTE: this implementation isn't complete and doesn't account for all possible
  // permutations of pastDueSpec, but it works for cases that match either { none: true }, 
  // { since: 12 }, or { since: 24, days: 30, max: 2 }
  public evalPastDue(spec: PastDueSpec, i: string): string {
    if (spec.none) {
      if (this.hasLatePayment) {
        return `[${i}] has late payments`
      }
    } else if (spec.since) {
      const { since, max, days } = spec
      const latePays = this.countLatePayments(since)
      if (days) {
        // map the days to corresponding code; e.g., 30 days = code 2, 60 days = code 3, etc
        const code = (days / 30) + 1
        // sum the count of late pays greater or equal to the days specified
        const countLatePaysPastDays = Object.keys(latePays).reduce((acc, past) => {
          return parseInt(past) >= code ? acc + latePays[past] : acc
        }, 0)

        if (max && countLatePaysPastDays > max) {
          return `[${i}] has more than ${max} ${days}-days past due in last ${since} months`
        } else if (!max && countLatePaysPastDays > 0) {
          return `[${i}] has ${countLatePaysPastDays} ${days}-days past due in last ${since} months`
        }
      } else {
        const count = Object.keys(latePays).reduce((acc, past) => {
          const key = parseInt(past)
          return acc + latePays[key]
        }, 0)

        if (count > 0) {
          return `[${i}] has ${count} past dues in last ${since} months`
        }
      }
    }
  }

  public countLatePayments(months: number): LatePaySummary {
    /**
     * 0 = Too new to rate; Approved but not used
     * 1 = Pays account as agreed
     * 2 = Not more than two payments past due
     * 3 = Not more than three payments past due
     * 4 = Not more than four payments past due
     * 5 = At least 120 days or more than four payments past due
     * 6 = Collection account (Enhanced Trade Only)
     * 7 = Included in Chapter 13
     * 8 = Repossession
     * 9 = Charge-off
     * Blank = No rate reported
     */
    const map = { 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 }
    for (const trade of this.trades) {
      // this appears to show even if the trade history doesn't show past due payments
      // if (trade.pastDueAmount) {
      //   return true
      // }

      const monthsAgo = trade.dateReported ? monthsSince(trade.dateReported) : 0
      const monthsToConsider = months - monthsAgo

      if (trade.paymentHistory1to24?.length) {
        for (let i = 0; i < monthsToConsider; i++) {
          const record = trade.paymentHistory1to24[i]
          const c = record?.code as string
          if (["2", "3", "4", "5", "6", "7", "8", "9"].includes(c)) {
            map[c]++
          } else if (["*", "/", "1", "E", " ", undefined].includes(c)) {
            // all fine, see docs
          } else {
            console.warn(`unknown payment history record`, trade.paymentHistory1to24[i])
          }
        }
      }
    }

    return map
  }

  private count306090s(): ThirtySixtyNinetySummary {
    const counts = { 30: 0, 60: 0, 90: 0 }
    for (const trade of this.trades) {
      counts[30] += trade.thirtyDayCounter || 0
      counts[60] += trade.sixtyDayCounter || 0
      counts[90] += trade.ninetyDayCounter || 0
    }

    return counts
  }
}

