import { ArrayEditor } from "../forms/array-editor"

export const TenantHostnames = ({ config, setConfig }) => {
  return <div className="tenant-hostnames">
    <div>
      <div className="form-group">
        <label>Consumer Site Hostname</label>
        <input
          type="text"
          value={config.consumerHostname}
          className="form-control"
          onChange={ev => setConfig({ ...config, consumerHostname: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label>Installer Site Hostname</label>
        <input
          type="text"
          value={config.installerHostname}
          className="form-control"
          onChange={ev => setConfig({ ...config, installerHostname: ev.target.value })}
        />
      </div>
    </div>
    <div className="alt-hosts ms-3">
      <label>Alternate Hostnames</label>
      <div>
        <ArrayEditor
          prompt='Add new hostname'
          values={config.altDomains || []}
          itemType='hostnames'
          onChange={altDomains => setConfig({ ...config, altDomains })}
        />
      </div>
    </div>
  </div>
}

export default TenantHostnames