import React, { useState } from 'react'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { Section, DataSync } from '../components'
import RadioToggle from '../components/radio-toggle'
import { errorToString } from '@oneethos/shared'
import { toast } from 'react-toastify'
import { DesensitizeCreditReports } from '../components/desensitize-credit-reports'


const AnonymizationDebugger = () => {
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState('')
  const [kind, setKind] = useState('email')
  const [len, setLen] = useState(undefined)
  const [result, setResult] = useState('')

  return (
    <div>
      <h4>Anonymization Debugger</h4>
      <p>
        Anonymization is a one-way process that cannot be reversed (aka, a hash
        function). Use this tool to check the anonymized value for a given
        input.
      </p>
      <div className="form-group">
        <label>Value Type</label>
        <RadioToggle
          value={kind}
          onChange={v => setKind(v)}
          options={[
            { label: 'Email', value: 'email' },
            { label: 'Phone', value: 'phone' },
            { label: 'Name', value: 'name' },
            { label: 'Alpha', value: 'alpha' },
            { label: 'Numeric', value: 'numeric' },
          ]}
        />
      </div>
      <div className="form-group">
        <label>Input</label>
        <input
          type="text"
          className="form-control"
          value={input}
          onChange={(ev) => setInput(ev.target.value)}
        />
      </div>
      {['alpha', 'numeric'].includes(kind) ? <div className="form-group">
        <label>Length</label>
        <input
          type="number"
          className="form-control"
          value={len}
          onChange={(ev) => setLen(ev.target.value)}
        />
      </div> : null}
      <div className="buttons mb-3">
        <button
          className="btn btn-primary"
          disabled={loading}
          onClick={() => {
            setLoading(true)
            const query = new URLSearchParams({ input, kind, len })
            api.get('/anonymize?' + query.toString())
              .then(res => setResult(res.result))
              .catch((err) => toast.error(errorToString(err)))
              .finally(() => setLoading(false))
          }}
        >
          {loading ? <Spinner /> : "Check Anonymized Value"}
        </button>
      </div>
      {result ? <div>
        <h5>Result</h5>
        <pre>{result}</pre>
      </div> : null}
    </div>
  )
}

const ErrorTester = () => {
  const [err, setErr] = useState(null)

  if (err) {
    return <div>
      {err.testRenderError.shouldFail()}
    </div>
  }

  return <>
    <h4>Error Testing</h4>
    <p>
      Clicking the following buttons should trigger the error handlers and
      report the event to error tracking systems (namely sentry).
    </p>
    <div className="buttons">
      <button
        className="btn btn-danger"
        onClick={() => {
          throw new Error('This is a thrown error')
        }}
      >Test Thrown Error</button>
      <button
        className="btn btn-warning"
        onClick={() => setErr(true)}
      >Test Rendering Error</button>
    </div>
  </>
}

export const ToolsPage = () => {
  return <>
    <Section>
      <AnonymizationDebugger />
    </Section>
    <Section>
      <h4>Danger Zone</h4>
      <p>Please make sure you know what you're doing when pushing these buttons :)</p>
      <div className="mb-3">
        <DataSync />
      </div>
      <div className="mb-3">
        <DesensitizeCreditReports />
      </div>
    </Section>
    <Section>
      <ErrorTester />
    </Section>
  </>
}

export default ToolsPage
