import { FaCheckCircle } from "react-icons/fa"
import './must-click-link.scss'

export const MustClickLink = ({ url, label, onClicked, clicked }) => {
  const markClicked = () => {
    onClicked()
  }

  return <span className={["must-click", clicked ? 'clicked' : ''].join(' ')}>
    <a href={url} target="_blank" onClick={markClicked}>
      {label}
      <span className="mc-icon">
        {clicked
          ? <FaCheckCircle color="green" fontSize="1.5em" />
          : <span className="unclicked">!</span>}
      </span>
    </a>
  </span>
}

