import { errorToString } from '@oneethos/shared'
import { useState } from 'react'
import api from '../api-client'
import { toast } from 'react-toastify'
import { Spinner } from '@fluentui/react'

export const DesensitizeCreditReports = () => {
  const [loading, setLoading] = useState(false)
  return <div className="desensitize-credit-reports">
    <div className="mb-3">
      Used to run a one-time desensitization of legacy credit reports.
      Future reports will be desensitized automatically.
    </div>
    <div className="buttons">
      <button
        className="btn btn-danger"
        disabled={loading}
        onClick={() => {
          setLoading(true)
          api.put('/credit-reports/desensitize', {})
            .then(res => toast.success(res.message))
            .catch(ex => toast.error(errorToString(ex)))
            .finally(() => setTimeout(() => setLoading(false), 3_000))
        }}
      >{loading ? <Spinner /> : 'Desensitize Legacy Credit Reports'}</button>
    </div>
  </div>
}

