import { useEffect, useState } from "react"
import { FaCheckSquare, FaRegSquare, FaTrash } from "react-icons/fa"
import EquifaxDitReport from "./components/equifax-dit-report"
import { CreditReportItem, jsonToCreditItem } from "./credit-report-item"
import api from "./api-client"
import { errorToString } from "@oneethos/shared"

export const CreditReportTester = () => {
  const [reports, setReports] = useState([])
  const [err, setErr] = useState('')
  const [combinedAnnualIncome, setCombinedIncome] = useState(0)
  const [estimatedPayment, setEstimatedPayment] = useState(0)
  const [results, setResults] = useState([])

  const extractReports = (text) => {
    const json = JSON.parse(text)
    if (Array.isArray(json)) {
      const list = json.map(jsonToCreditItem)
      setReports([...reports, ...list])
    } else if (Array.isArray(json.list)) {
      const list = json.list.map(jsonToCreditItem)
      setReports([...reports, ...list])
    } else if (json?.reportType === "prequal") {
      const report = jsonToCreditItem(json)
      setReports([...reports, report])
    }

    setErr('')
  }

  const readFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText()
      extractReports(text)
    } catch (err) {
      setErr(`Failed to parse credit reports: ${err.message}`)
    }
  }

  const handlePaste = async (event: ClipboardEvent) => {
    const activeElement = document.activeElement
    if (
      activeElement && (
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA" ||
        activeElement.isContentEditable
      )
    ) {
      // Ignore paste event if an input, textarea, or contenteditable element is focused
      return
    }

    try {
      const text = event.clipboardData.getData("text")
      extractReports(text)
    } catch (err) {
      setErr(`Failed to parse credit reports: ${err.message}`)
    }
  }

  useEffect(() => {
    document.addEventListener("paste", handlePaste)
    return () => {
      document.removeEventListener("paste", handlePaste)
    }
  }, [])

  const selected = reports.filter(r => r.selected)
  const slen = selected.length

  return (
    <div className="credit-report-tester">
      <h3>Credit Report Tester</h3>
      <div className="alert alert-light">
        <p><b>Notes:</b></p>
        <ul>
          <li>Use this tool to test evaluations of credit reports</li>
          <li>Pasted reports must be in JSON format</li>
          <li>None of the data in this tool will be saved.</li>
          <li>
            Best way to get reports JSON is to copy JSON response
            from <b>/api/credit-reports?loanAppId=:id</b> response in chrome debugger
          </li>
          <li>Select 2 individual reports to calculate the combined evaluation</li>
          <li>
            This tools uses the current date
            for evaluation so there may be some discrepancies
          </li>
        </ul>
      </div>
      <div className="combined-config mb-3 d-inline-block bg-light p-3 rounded">
        <h5>Test {slen === 2 ? "Combined" : ""} Reports</h5>
        <div className="form-group">
          <label>{slen === 2 ? "Combined" : ""} Annual Income</label>
          <input type="number"
            className="form-control"
            disabled={![1, 2].includes(slen)}
            value={combinedAnnualIncome}
            onChange={ev => setCombinedIncome(parseFloat(ev.target.value))}
          />
        </div>
        <div className="form-group">
          <label>Estimated Monthly Loan Payment</label>
          <input type="number"
            className="form-control"
            disabled={![1, 2].includes(slen)}
            value={estimatedPayment}
            onChange={ev => setEstimatedPayment(parseFloat(ev.target.value))}
          />
        </div>
        <div className="buttons">
          <button className="btn btn-outline-secondary btn-sm"
            disabled={![1, 2].includes(slen)}
            onClick={() => {
              const summer = (acc, r) => acc + (
                r.item.evaluation?.statedGrossMonthlyIncome ||
                r.item.evaluation?.statedIncome || 0)
              const calculated = selected.reduce(summer, 0)
              setCombinedIncome(calculated * 12)
              setEstimatedPayment(selected[0].item.evaluation?.estimatedPayment || 0)
            }}>Use Existing Values</button>
          <button
            className="btn btn-outline-primary btn-sm"
            disabled={![1, 2].includes(slen)}
            onClick={() => {
              const reps = reports.filter(r => r.selected)
              const endpoint = `/credit-reports/${reps.length === 2 ? 'test-combined' : 'test-eval'}`
              api.post(endpoint, {
                reports: reps,
                combinedAnnualIncome,
                estimatedPayment
              }).then(res => setResults(res.map(jsonToCreditItem)))
                .catch(ex => setErr(errorToString(ex)))
            }}>Evaluate {slen === 2 ? "Combined" : ""}</button>
          <button className="btn btn-outline-secondary btn-sm" onClick={() => {
            setReports([])
            setResults([])
            setErr('')
          }}>Clear Reports</button>
        </div>
      </div>
      {err && <div className="alert alert-danger">{err}</div>}
      <div style={{ display: 'flex' }}>
        <div className="reports" style={{ flex: 1 }}>
          <h5>Pasted Reports</h5>
          {reports.length === 0 ? <div className="alert alert-secondary">Paste reports here</div> : null}
          {reports.map((entry, i) => {
            const isPrequal = ['prequal', 'prequal-combined'].includes(entry.item.reportType)
            return <div className="cr-wrap mb-3" key={i}>
              {isPrequal ? <CreditReportItem
                report={entry.report}
                item={entry.item}
              /> : <EquifaxDitReport report={entry.item.report} />}

              <div className="actions">
                {entry.item.reportType === 'prequal' ? <div className="action"
                  onClick={ev => {
                    const _reports = [...reports]
                    _reports[i].selected = !_reports[i].selected
                    setReports(_reports)
                  }}
                >{entry.selected ? <FaCheckSquare color="green" /> : <FaRegSquare />}</div> : null}
                <div className="action" onClick={() => {
                  const _reports = [...reports]
                  _reports.splice(i, 1)
                  setReports(_reports)
                }}><FaTrash /></div>
              </div>
            </div>
          })}
        </div>
        {results.length ? <div className="combined-results ms-3" style={{ flex: 1 }}>
          <h5>Evaluated Results</h5>
          <div className="reports" style={{ flex: 1 }}>
            {results.map((entry, i) => {
              const isPrequal = ['prequal', 'prequal-combined'].includes(entry.item.reportType)
              return <div className="cr-wrap mb-3" key={i}>
                {isPrequal ? <CreditReportItem
                  report={entry.report}
                  item={entry.item}
                /> : <EquifaxDitReport report={entry.item.report} />}
              </div>
            })}
          </div>
        </div> : null}
      </div>
    </div>
  )
}