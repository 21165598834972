import React from 'react'

type TabNavProps = {
  tabs: string[]
  current: string
  onNav: (s: string) => void
}

export const TabNav = ({ tabs, current, onNav }: TabNavProps) => (
  <ul className="nav nav-tabs mb-3">
    {tabs.map((label, i) => <li key={i} className="nav-item">
      <span className={`nav-link ${current === label ? 'active' : ''}`}
        onClick={() => onNav(label)}>{label}</span>
    </li>)}
  </ul>
)