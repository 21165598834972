import React from 'react'

type LinkProps = {
  href: string
  label?: string
  target?: string
  title?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  onClick?: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const Link = ({
  href,
  label,
  style,
  target,
  title,
  onClick,
  children
}: LinkProps) => {
  return <div className="link link-wrap" style={style}>
    <a title={title} href={href} target={target} onClick={onClick}>{label || children}</a>
  </div>
}