import { useEffect, useRef, useState } from "react"

export const AutoHorizontalScroller = ({ children }) => {
  const elementRef = useRef(null)
  const [width, setWidth] = useState<number | 'auto'>('auto')
  const [display, setDisplay] = useState('none')

  useEffect(() => {
    if (elementRef.current) {
      // tried using ResizeObserver but it kept giving undelivered notification errors
      // todo: consider debouncing resize
      const handleResize = () => {
        setWidth(elementRef.current.offsetWidth)
      }

      handleResize() // Get initial width
      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }

  }, [])

  useEffect(() => {
    if (width > 0) {
      setDisplay('')
    }
  }, [width])

  return <div ref={elementRef}>
    <div style={{
      width,
      overflowX: 'auto',
      display
    }}>{children}</div>
  </div>
}

