import React from 'react'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'

type MultiSelectProps = {
  options: Record<string, string>
  values: string[]
  onChange: (a: string[]) => void
  wrapStyle?: React.CSSProperties
}

export const MultiSelect = ({ options, values, onChange, wrapStyle }: MultiSelectProps) => {
  return <div className="multi-select list-group" style={{ minWidth: '20em', ...wrapStyle }}>
    {Object.entries(options).map(([val, label], idx) => {
      const selected = values?.includes(val)
      return <div key={val}
        className={['list-group-item p-2'].join(' ')}
        onClick={() => {
          let vals = [...values]
          if (selected) {
            vals = vals.filter(v => v !== val)
          } else {
            vals.push(val)
          }
          onChange(vals)
        }}
      >
        <div className="chk-cont me-2">
          {selected
            ? <RiCheckboxCircleFill color="green" size={20} />
            : <RiCheckboxBlankCircleLine color="#aaa" size={20} />
          }
        </div>
        <span>{label}</span>
      </div>
    })}
  </div>
}
