import { Spinner } from "@fluentui/react"
import { errorToString, Org } from "@oneethos/shared"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import api from "../api-client"


export const AddOrg = () => {
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [org, setOrg] = useState<Partial<Org>>({})

  return <div>
    <h2>Add Org</h2>
    <div className="form-group">
      <label>Company Name</label>
      <input
        type="text"
        value={org.companyName || ''}
        className="form-control"
        onChange={ev => setOrg({ ...org, companyName: ev.target.value })}
      />
    </div>
    <div className="form-group">
      <label>InstallerID</label>
      <input type="text"
        value={org.installerID || ''}
        className="form-control"
        onChange={ev => setOrg({ ...org, installerID: ev.target.value })}
      />
    </div>
    <button type="submit"
      disabled={saving}
      onClick={() => {
        setSaving(true)
        api.post(`/orgs`, org)
          .then(_org => {
            toast.success('Org Added'); 
            navigate(`/installer-admin/${_org.installerID}`)
          })
          .catch(ex => {
            toast.error(errorToString(ex));
            setSaving(false)
          })
      }}
      className="btn btn-primary">{saving ? <Spinner /> : 'Add Org'}</button>
  </div>
}

