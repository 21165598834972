import { Section } from './section'
import { useAppState } from '../hooks'
import { IProduct } from '@oneethos/shared'

export const LoanInformation = ({ isResidential }) => {
  const { tenant: { config: { products } } } = useAppState()
  const residential = products.find((product: IProduct) => product.productType === 'residential')

  return (
    <Section>
      <div className="section-title">
        <h2 className="margin-bottom-0">Loan Information</h2>
      </div>
      <ul className="list" style={{ fontWeight: 600, fontSize: '1.2em' }}>
        <li>
          Clients will save between 20%-30% on project cost
          as OneEthos does not charge dealer fees.
        </li>
        {isResidential ? <>
          <li>Clients can benefit from a 30% tax credit (if applicable). They can choose to
            apply the credit to towards the loan, bringing the monthly payment down.</li>
          <li>Clients’ monthly payment will never increase.</li>
          <li>30-year loan term.</li>
          <li>
            Clients benefit from a {residential?.interestRateWithAutopay}% interest rate
            with auto pay, or {residential?.interestRateWithoutAutopay}% without auto pay.
          </li>
        </> : <>
          <li>
            Clients can keep any tax credit. They can choose to apply
            the credit to towards the loan, bringing the monthly payment down.
          </li>
          <li>25-year loan term.</li>
        </>}
      </ul>
    </Section>
  )
}

export default LoanInformation