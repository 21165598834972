import React, { useState } from 'react'
import { yyyymmdd, ITenant, dateAndTime, Validate } from '@oneethos/shared'

interface Props {
  config: Partial<ITenant>
  onSave: (config: Partial<ITenant>) => void
}

export const TermsManagement = ({ config, onSave }: Props) => {
  const [newTermsUrl, setNewTermsUrl] = useState('')
  const [newTermsVersion, setNewTermsVersion] = useState('')

  const handleAddTerms = async () => {
    if (!window.confirm([
      'Are you sure you want to add new terms? This will force all installers to ',
      'accept the new terms before continuing to use the application.\n\nThis will also ',
      'save any unsaved tenant configuration changes. ',
      'This action cannot be undone.'
    ].join(''))) {
      return
    }

    onSave({
      ...config,
      installerTerms: [
        ...(config.installerTerms || []),
        {
          url: newTermsUrl,
          version: newTermsVersion,
          createdDate: new Date()
        }
      ]
    })
    setNewTermsUrl('')
    setNewTermsVersion('')
  }

  const terms = config.installerTerms || []
  const urlError = newTermsUrl ? Validate.isValidUrl(newTermsUrl) : ''

  return (
    <div className="mb-3">
      <div>
        <p>
          Adding new terms will force all installers to accept
          the new terms before continuing to use the application.
        </p>
        <div className="mb-4">
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: '20%' }}>Version</th>
                <th style={{ width: '60%' }}>URL</th>
                <th style={{ width: '20%' }}>Added</th>
              </tr>
            </thead>
            <tbody>
              {terms.map((record, idx) => {
                const isCurrent = idx === terms.length - 1
                return (
                  <tr key={record.version} className={!isCurrent ? 'table-secondary' : ''}>
                    <td>
                      {record.version}
                      {isCurrent && <div className="ms-3 badge bg-info">Current</div>}
                    </td>
                    <td>
                      <div className="text-break">
                        <a href={record.url} target="_blank" rel="noopener noreferrer">
                          {record.url}
                        </a>
                      </div>
                    </td>
                    <td>{dateAndTime(new Date(record.createdDate))}</td>
                  </tr>
                )
              })}
              {terms.length === 0 && <tr><td colSpan={3}>No terms configured</td></tr>}
            </tbody>
          </table>
        </div>

        <div>
          <label>Add New Terms Version</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={newTermsVersion}
              onChange={e => setNewTermsVersion(e.target.value)}
              placeholder="Enter terms version"
            />
            <input
              type="url"
              className="form-control"
              value={newTermsUrl}
              onChange={e => setNewTermsUrl(e.target.value)}
              placeholder="Enter terms URL"
            />
            <button
              className="btn btn-primary"
              onClick={handleAddTerms}
              disabled={!newTermsUrl || !newTermsVersion || !!urlError}
            >Add...</button>
          </div>
        </div>
        {urlError && <div className="mt-3 alert alert-danger">Terms URL must be a valid URL</div>}
      </div>
    </div>
  )
}

export default TermsManagement