import React, { useState, useContext } from 'react'
import { Spinner, SpinnerSize } from '@fluentui/react'
import { MustClickLink } from './must-click-link'
import { AgreementType, Audience } from '@oneethos/shared'
import { Modal } from './modal'
import api from '../api-client'
import { toast } from 'react-toastify'
import * as Sentry from "@sentry/react"
import { AppContext } from '../appContext'
import { getBrowserFingerprintData } from '../browser-fingerprint'
import actions from '../actions'

export const InstallerTermsModal = ({ terms }) => {
  const [hasClickedTerms, setHasClickedTerms] = useState(false)
  const [agrees, setAgrees] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const { dispatch } = useContext(AppContext)

  return <Modal title="Installer Terms of Service" dismissable={false}>
    <p>
      We have updated our <MustClickLink
        url={terms.url}
        label={`Installer Terms of Service (v${terms.version})`}
        onClicked={() => setHasClickedTerms(true)}
        clicked={hasClickedTerms}
      />. Please review and accept these terms to continue using OneEthos.
    </p>
    {hasClickedTerms ? <>
      <div className="form-group" style={{ cursor: 'pointer', marginTop: '3em' }}>
        <label>
          <input type="checkbox"
            checked={agrees}
            disabled={!hasClickedTerms}
            onChange={ev => setAgrees(ev.target.checked)}
          />
          &nbsp;
          <b>I have read and agree with the Installer Terms of Service.</b>
        </label>
      </div>
      <div className="buttons text-center">
        <button type="button" className="btn btn-primary"
          disabled={!hasClickedTerms || !agrees || submitting}
          onClick={() => {
            if (!agrees) {
              return
            }

            setSubmitting(true)
            api.post('/consents/accept', {
              browser: getBrowserFingerprintData(),
              agrees,
              agreementUrl: terms.url,
              agreementType: AgreementType.InstallerTerms,
              version: terms.version,
              audience: Audience.Installer
            }).then(() => {
              return api.get('/installers/me').then(installer => {
                setSubmitting(false)
                toast.success('Thank you for accepting the terms of service.')
                dispatch(actions.setInstaller(installer))
              })
            }).catch(ex => {
              setSubmitting(false)
              toast.error('Error recording agreements, please try again. If the problem persists, please contact support.')
              Sentry.captureException(ex, {
                tags: {
                  error: 'Error recording installer agreements'
                }
              })
            })
          }}
        >{submitting ? <Spinner size={SpinnerSize.small} /> : 'Submit'}</button>
      </div>
    </> : <div className="alert alert-info mt-5">
      You must click the link above to review the document before continuing.
    </div>}
  </Modal>
}

