export interface IStreetAddress {
  streetAddress?: string
  streetAddressLine2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export const STATE_CODES = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  VI: "Virgin Islands",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
}

export class StreetAddress {
  streetAddress?: string
  streetAddressLine2?: string
  city?: string
  state?: string
  zip?: string
  country?: string

  constructor(a: IStreetAddress) {
    this.streetAddress = a.streetAddress
    this.streetAddressLine2 = a.streetAddressLine2
    this.city = a.city
    this.state = a.state
    this.zip = a.zip
    this.country = a.country
  }

  static isValid(a: StreetAddress): boolean {
    return a && !!(
      a.streetAddress &&
      a.city &&
      a.state && STATE_CODES.hasOwnProperty(a.state) &&
      a.zip &&
      a.country
    )
  }

  static toString(a: StreetAddress): string {
    if (StreetAddress.isValid(a)) {
      const line2 = a.streetAddressLine2 ? ' ' + a.streetAddressLine2 : ''
      return `${a.streetAddress}${line2}, ${a.city}, ${a.state} ${a.zip}`
    } else {
      return '[invalid street address]'
    }
  }

  static line1(a: StreetAddress): string {
    return a.streetAddress || ''
  }

  static cityStZip(a: StreetAddress): string {
    return `${a.city}, ${a.state} ${a.zip}`
  }
}


