import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Section } from '../components'
import { LuSearchX } from "react-icons/lu"
import { IoIosCheckmark, IoIosClose } from "react-icons/io"

export const SearchResultsPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const installers = location.state?.installers || []

  const sortedInstallers = useMemo(() => {
    return [...installers].sort((a, b) => a.email.localeCompare(b.email))
  }, [installers])

  return (
    <>
      <Section>
        {sortedInstallers.length > 0 ? (
          <>
            <div className="section-title">
              <h2 className="margin-bottom-0">Search Results</h2>
            </div>
            <div className="table-list-wrap">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Installer ID</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Access Level</th>
                    <th className="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedInstallers.map((installer) => (
                    <tr 
                      className="align-middle" 
                      key={installer._id} 
                      style={{ cursor: 'pointer' }} 
                      onClick={() => navigate(`/installer-admin/${installer?.installerID}`)}
                    >
                      <td>{installer.installerID}</td>
                      <td>{installer.name}</td>
                      <td>{installer.email}</td>
                      <td>{installer.access}</td>
                      <td className="text-center">{installer.active !== false ? <IoIosCheckmark color='green' size={35} /> : <IoIosClose color='red' size={35} />}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className='flex-column justify-content-center text-center'>
            <LuSearchX size={40} className='mb-2' />
            <p className='fs-5'>We couldn't find anything matching your search.</p>
            <p>Try again with a different input.</p>
          </div>
        )}
      </Section>
    </>
  )
}

export default SearchResultsPage
