import { Spinner } from "@fluentui/react"
import { AccessLevel, errorToString, Org } from "@oneethos/shared"
import { useState } from "react"
import { toast } from "react-toastify"
import api from "../api-client"
import { useAppState } from "../hooks"


export const NewApiKey = ({ onNewKey, org }) => {
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [integrationId, setIntegrationId] = useState('')
  const { registration: { installer } } = useAppState()

  return showForm ? <div>
    <h4>Create API Key</h4>
    <div className="alert alert-warning">
      <p className="mb-0">
        <b>Be Aware:</b> Do not create API keys and email them to anyone else - this
        is extremely insecure. Only create API keys if you intend to use them directly. If the API
        is enabled for an org, Org Admins should create their own API keys, or integration Admins
        can create them on behalf of your Org.
      </p>
    </div>
    <div className="form-group">
      <p>
        Select an Integration to use with this API Key. If selected, API activity
        from this key will be visible to integration administrators.
      </p>
      <select className="form-select"
        value={integrationId}
        onChange={ev => setIntegrationId(ev.target.value)}
        style={{ maxWidth: '400px' }}
      >
        <option value="">Select Integration...</option>
        {(
          installer.installerID === org.installerID ||
          installer.isSupport
        ) ? <option value="internal">Internal Use</option> : null}
        {org.enabledIntegrations.map(integrationId => (
          <option key={integrationId} value={integrationId}>Integration: {integrationId}</option>
        ))}
      </select>
    </div>
    <div className="buttons">
      <button className="btn btn-primary"
        disabled={!integrationId}
        onClick={() => {
          setSaving(true)
          api.post('/api-keys', { orgId: org._id, integrationId }).then(key => {
            onNewKey(key)
            setSaving(false)
          }).catch(ex => {
            toast.error(errorToString(ex))
            setSaving(false)
          })
        }}>Create API Key</button>
      <button className="btn btn-secondary" onClick={() => setShowForm(false)}>Cancel</button>
    </div>
  </div> : <button
    className="btn btn-primary"
    disabled={saving}
    onClick={() => setShowForm(true)}
  >{saving ? <Spinner /> : 'Configure New API Key...'}</button>
}

