import { UploadedDoc } from './document-types'

export enum AgreementType {
  InstallerTerms = 'installerTerms',
  ConsumerTerms = 'consumerTerms',
  PrivacyPolicy = 'privacyPolicy',
  EsignAgreement = 'esignAgreement'
}

export enum Audience {
  Installer = 'installer',
  Consumer = 'consumer'
}

export interface AgreementDoc extends UploadedDoc {
  _id: string // hash of agreement contents
  url: string
}
