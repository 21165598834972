import React from "react"
import "./modal.scss"
import { IoClose } from "react-icons/io5"

type ModalProps = {
  onClose?: () => void
  title?: string
  children: React.ReactNode
  dismissable?: boolean
}

export const Modal = ({ onClose, title, children, dismissable = true }: ModalProps) => {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        {dismissable ? <div className="close-button">
          <IoClose onClick={onClose}/>
        </div> : null}
        {title ? <h2 className="text-center fs-3">{title}</h2> : null}
        <div className="modal-body">{children}</div>
        {dismissable ? <div className="text-center">
          <button onClick={onClose} className="btn btn-secondary">
            Close
          </button>
        </div> : null}
      </div>
    </div>
  )
}
