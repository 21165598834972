import React, { useContext, useState } from 'react'
import {
  EquifaxDitConfig,
  EquifaxPrequalConfig,
  errorToString,
  ITenant,
  TenantEquifaxConfig
} from '@oneethos/shared'
import { TabNav } from '../'
import api from '../../api-client'
import { toast } from 'react-toastify'
import { AppContext } from '../../appContext'
import actions from '../../actions'
import { EquifaxPrequalForm } from './equifax-prequal-form'
import { EquifaxDITForm } from './equifax-dit-form'
import { BackendConfig } from './backend-config'
import { FundingConfig } from './funding-config'
import { PlaidForm } from './plaid-form'
import { TenantConfig } from './tenant-config'
import { TermsManagement } from './terms-management'
import TenantProductList from '../tenant-product-list'
import './tenant-form.scss'

type TenantFormProps = {
  initialConfig: ITenant
}

export const TenantForm = ({ initialConfig }: TenantFormProps) => {
  const [config, setConfig] = useState<Partial<ITenant>>(initialConfig)
  const { dispatch } = useContext(AppContext)
  const [nav, setNav] = useState('Info')

  const setEquifaxConfig = (props: Partial<TenantEquifaxConfig>) => {
    setConfig({
      ...config,
      equifax: {
        ...config.equifax,
        ...props
      }
    })
  }

  const saveSettings = (stx?: Partial<ITenant>) => {
    const payload = stx || { ...config }
    delete payload._id
    delete payload.dbName
    api.patch(`/tenant`, payload).then(res => {
      toast.success('Tenant settings updated')
      dispatch(actions.setTenant(res))
      setConfig(payload)
    }).catch(ex => {
      toast.error(errorToString(ex))
    })
  }

  const dit: EquifaxDitConfig = config.equifax?.dit || {
    configId: 'configId',
    entityId: 'entityId'
  }
  const prequal: EquifaxPrequalConfig = config.equifax?.prequal || {}

  return (
    <div className="form-group">
      <TabNav
        tabs={[
          'Info',
          'Products',
          'Backend',
          'Equifax Prequal',
          'Equifax DIT',
          'Plaid',
          'Funding',
          'Installer Terms'
        ]}
        current={nav}
        onNav={setNav}
      />

      {nav === 'Info' ? <TenantConfig config={config} setConfig={setConfig} /> : null}
      {nav === 'Funding' ? <FundingConfig config={config} setConfig={setConfig} /> : null}
      {nav === 'Backend' ? <BackendConfig config={config} setConfig={setConfig} /> : null}
      {nav === 'Equifax Prequal' ? <EquifaxPrequalForm prequal={prequal} setEquifaxConfig={setEquifaxConfig} /> : null}
      {nav === 'Equifax DIT' ? <EquifaxDITForm dit={dit} setEquifaxConfig={setEquifaxConfig} /> : null}
      {nav === 'Plaid' ? <PlaidForm config={config} setConfig={setConfig} /> : null}
      {nav === 'Installer Terms' ? <TermsManagement config={config} onSave={stx => saveSettings({ ...stx })} /> : null}
      {nav === 'Products' ? <TenantProductList /> : null}

      {['Products', 'Installer Terms'].includes(nav) ? null : <div className="buttons">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => saveSettings()}
        >Save Tenant Settings</button>
      </div>}
    </div >
  )
}

export default TenantForm
