import { dollars, IProduct, paymentByTerm } from "@oneethos/shared"
// import PayoffScenarios from "./payoff-scenarios"
// import LoanProductInfo from "./loan-product-info"

export const LoanDetails = ({
  financingTenant,
  formData,
  isResidential,
  rateEffectiveDate,
  loanPlusClosingCosts,
  incentive,
  product
}) => {
  const loanTermYears = (product?.term / 12).toFixed(1)
  const interestRate = (product, withAutopay = true) =>
    withAutopay ? product?.interestRateWithAutopay : product?.interestRateWithoutAutopay

  const calculatePayment = (p: IProduct, withAutopay = true) => paymentByTerm(
    interestRate(p, withAutopay),
    -p?.term,
    loanPlusClosingCosts
  )

  const estimatedPaymentWithAutopay = calculatePayment(product)
  const estimatedPaymentWithoutAutopay = calculatePayment(product, false)

  return (
    <>
      <section className="list-item">
        <div className="mt-6 d-flex">
          <div className="form-group">
            <label>Financing Provided By:</label>
            <img
              src={`/api/tenant/logo/${financingTenant._id}`}
              loading="lazy"
              alt="financing provider logo"
              style={{ maxHeight: '50px' }}
            />
          </div>
          {incentive ? <div className="form-group">
            <label>Financial Partner:</label>
            <img
              src={incentive.financingPartnerLogo}
              loading="lazy"
              id="logo"
              sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
              alt="local financing partner logo"
              style={{ maxHeight: '50px' }}
            />
          </div> : null}
        </div>
        {rateEffectiveDate && (
          <p className="fw-bold text-black">
            Please note that OUR RATES have changed effective {rateEffectiveDate}.
          </p>
        )}
        {product && (
          <>
            <h6 className="heading-7">{loanTermYears} year term<br /></h6>
            <h6 className="heading-7">
              {interestRate(product)}%
              {isResidential ? '(with Auto Pay)' : null}
              <br />
            </h6>
            <h6 className="heading-7">No Dealer Fee<br /></h6>

            {formData?.projectType === 'residential' && (
              <p className="text-secondary-emphasis">
                {financingTenant.name} offers a discount to clients that opt into, and maintain,
                automatic monthly payments. The bill amount will automatically be withdrawn
                from the clients checking account.
              </p>
            )}
          </>
        )}
        <div className="oe-pmt-summary">
          <div className="text-block-4">Estimated Payment:</div>
          <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
            {isResidential ? estimatedPaymentWithAutopay : estimatedPaymentWithoutAutopay}
          </div>

          {isResidential ? <div>
            <>
              <div className="text-block-4">w/o Auto Pay:</div>
              <div className="text-block-3" style={{ color: 'var(--neutral--700)' }}>
                {estimatedPaymentWithoutAutopay}
              </div>
            </>
          </div> : null}
        </div>
      </section>
    </>
  )
}

export default LoanDetails

