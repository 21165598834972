import { Fragment, useState } from "react"
import {
  CriteriaEvaluationResult,
  CriteriaSpec,
  EquifaxBankruptcy,
  EquifaxCollection,
  EquifaxPrequalReport,
  EquifaxScoreModel,
  EquifaxTrade,
  dollars,
  yyyymmdd,
  dateAndTime,
} from "@oneethos/shared"
import { FaChevronDown, FaChevronRight, FaEye, FaEyeSlash } from "react-icons/fa"
import { FaCircleCheck, FaCircleXmark } from "react-icons/fa6"
import { AutoHorizontalScroller, TabNav } from "../components"

export const jsonToCreditItem = json => {
  let report = json
  if (!["prequal", "prequal-combined"].includes(json.reportType) || !json?.report?.consumers) {
    throw new Error("Could not parse credit report")
  }

  report = new EquifaxPrequalReport(json.report.consumers?.equifaxUSConsumerCreditReport[0])
  report.createdDate = new Date(json.createdDate)

  return { report, item: json }
}

const CodedDescriptor = ({ code, description }) => {
  if (!code) return null

  return <div
    title={description}
    style={{
      whiteSpace: 'nowrap',
      maxWidth: '150px',
      fontSize: '0.8em',
      overflow: 'hidden',
      marginRight: '0.3em'
    }}><b>{code}</b> | <span style={{ color: '#aaa' }}>{description}</span></div>
}

const TradesByType = ({ trades }) => {
  const summary = {}

  trades.forEach(t => {
    let code = t.portfolioTypeCode?.code
    if (t.rawNarrativeCodes?.length) {
      for (const narrative of t.rawNarrativeCodes) {
        // codes that appear to considered mortgages
        if (['EF', 'EC'].includes(narrative)) {
          code = 'M'
          break
        }
      }
    }

    const val = summary[code] || 0
    if (
      // ignore revolving if authorized user
      (t.accountDesignator?.code === 'A' && code === 'R') ||
      // or if no balance
      !t.balance
    ) {
      return
    }

    summary[code] = val + (t.scheduledPaymentAmount || 0)
  })

  return <div>
    <div>Mortgage: {dollars(summary['M'] || 0)}</div>
    <div>Revolving: {dollars(summary['R'] || 0)}</div>
    <div>Installment: {dollars(summary['I'] || 0)}</div>
    <div>Credit Line: {dollars(summary['C'] || 0)}</div>
    <div>Other: {dollars(summary['O'] || 0)}</div>
    <div>Total: {Object.values(summary).reduce((prev: number, cur: number) => prev + cur, 0)}</div>
  </div>
}

const TradesTable = ({ trades, title }) => {
  return <div className="trades-cont mt-3">
    <div className={`bg-secondary text-light p-2`}>{title}</div>
    <AutoHorizontalScroller>
      <table className="table">
        <thead>
          <tr>
            <th>Rating</th>
            <th>Acct #</th>
            <th>Limit/High</th>
            <th>Bal</th>
            <th>Pmt</th>
            <th>Type</th>
            <th>Narrative</th>
            <th>Dates</th>
            <th>History</th>
            <th>Terms/Act/Purchased</th>
            <th>30</th>
            <th>60</th>
            <th>90</th>
          </tr>
        </thead>
        <tbody>
          {trades?.map((trade: EquifaxTrade, idx) => {
            return <Trade key={idx} trade={trade} />
          })}
        </tbody>
      </table>
    </AutoHorizontalScroller>
  </div>
}

const Trades = ({ trades }) => {
  const [expand, setExpand] = useState(false)

  const sharedTrades = trades.filter(t => t.isSharedWithCoapplicant)
  const ownTrades = trades.filter(t => !t.isSharedWithCoapplicant)

  return <div className="trades">
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Trades ({trades?.length || 0})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>
    {expand && trades?.length ? <>
      <TradesByType trades={trades} />
      {sharedTrades?.length ? <>
        <TradesTable trades={sharedTrades} title="Trades Shared with Co-Applicant" />
      </> : null}
      <TradesTable trades={ownTrades} title="Personal Trades" />
    </> : null}
  </div >
}

type TradeProps = {
  trade: EquifaxTrade
}

const mmyyyy = (date?: Date): string => {
  if (!date) return ''
  if (typeof date === 'string') return date

  return `${date.getMonth() + 1}/${date.getFullYear()}`
}

const TradeHistoryGrid = ({ trade }) => {
  if (!trade.dateReported) return <tr><td>NO LAST PAYMENT DATE</td></tr>

  const maxYear = trade.dateReported.getFullYear()
  const startMonth = trade.dateReported.getMonth()
  const history = [...trade.paymentHistory1to24]
  history.splice(12, 1)
  return <div>
    <div className="year">
      <div className="months m-hdr">
        <div className="year-label"></div>
        <div>J</div> <div>F</div> <div>M</div> <div>A</div>
        <div>M</div> <div>J</div> <div>J</div> <div>A</div>
        <div>S</div> <div>O</div> <div>N</div> <div>D</div>
      </div>
    </div>
    {[maxYear, maxYear - 1, maxYear - 2].map(year => <div className="year" key={year}>
      <div className="months">
        {[12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].map(month => {
          if (year === maxYear) {
            return <div key={month}>{month > startMonth ? '' : history[startMonth - month].code}</div>
          } else if (year === maxYear - 1) {
            return <div key={month}>{history[12 + startMonth - month].code}</div>
          } else {
            const idx = 24 + startMonth - month
            return <div key={month}>{idx < history.length ? history[idx].code : ''}</div>
          }
        })}
        <div className="year-label">{year}</div>
      </div>
    </div>)}
  </div>
}


const Trade = ({ trade }: TradeProps) => {
  const [showJson, setShowJson] = useState(false)

  let className = ''
  if (
    trade.rate?.code > 1 ||
    trade.thirtyDayCounter ||
    trade.ninetyDayCounter ||
    trade.sixtyDayCounter
  ) {
    className = 'table-danger'
  }

  return <>
    <tr className={className} onClick={() => setShowJson(!showJson)} style={{ marginBottom: '1em' }}>
      <td className="amt">{trade.rate?.code}</td>
      <td className="amt">{trade.accountNumber}</td>
      <td className="amt">{trade.creditLimit ? dollars(trade.creditLimit, 0) : (trade.highCredit ? dollars(trade.highCredit, 0) : '')}</td>
      <td className="amt">{dollars(trade.balance || 0, 0)}</td>
      <td className="amt">{dollars(trade.scheduledPaymentAmount || 0, 0)}</td>
      <td className="amt">{trade.portfolioTypeCode?.code}{trade.rawNarrativeCodes?.find(n => ['EF', 'EC'].includes(n)) ? '/M' : ''}</td>
      <td style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {trade.narrativeCodes?.map(({ description }, i) => (
          <div key={i} title={description}>{description}</div>
        ))}
      </td>
      <td className="trade-dates">
        <div>
          <span>Date Reported:</span>
          <span> {mmyyyy(trade.dateReported)}</span></div>
        <div>
          <span>Date Opened:</span>
          <span> {mmyyyy(trade.dateOpened)}</span></div>
        <div>
          <span>Last Payment Date:</span>
          <span> {mmyyyy(trade.lastPaymentDate)}</span></div>
        <div>
          <span>Date Closed:</span>
          <span> {mmyyyy(trade.closedDate)}</span></div>
        <div>
          <span>Months Reviewed:</span>
          <span> {trade.monthsReviewed}</span></div>
      </td>
      <td className="trade-history-grid">
        {trade.paymentHistory1to24 ? <TradeHistoryGrid trade={trade} /> : null}
      </td>
      <td>
        <CodedDescriptor {...trade.accountTypeCode} />
        <CodedDescriptor {...trade.accountDesignator} />
        <CodedDescriptor {...trade.termsFrequencyCode} />
        <CodedDescriptor {...trade.activityDesignatorCode} />
        <CodedDescriptor {...trade.purchasedFromOrSoldCreditorIndicator} />
      </td>
      <td>{trade.thirtyDayCounter}</td>
      <td>{trade.sixtyDayCounter}</td>
      <td>{trade.ninetyDayCounter}</td>
    </tr>
    {showJson ? <tr onClick={() => setShowJson(!showJson)}>
      <td colSpan={8}>
        <pre>
          {JSON.stringify(trade, false, ' ')}
        </pre>
      </td>
    </tr> : null}
  </>
}

type ScoreProps = { model: EquifaxScoreModel }

const Score = ({ model }: ScoreProps) => {
  const [expand, setExpand] = useState(false)

  return <div className="cr-scoring" >
    {model.rejects?.map(({ code }) => <div key={code} className="cr-reject score">Score: Reject ({code})</div>)}
    {model.score ? <>
      <div className="score" onClick={() => setExpand(!expand)}>
        Score: {model.score}
        {expand ? <FaChevronDown /> : <FaChevronRight />}
      </div>
      {expand ? <div>
        {model.reasons?.map(r => <div key={r.code}>
          <b>{r.code}</b> | <span>{r.description}</span>
        </div>)}
      </div> : null}
    </> : null
    }
  </div >
}

type BankruptcyProps = {
  bankruptcies: EquifaxBankruptcy[]
}

const Bankruptcies = ({ bankruptcies }: BankruptcyProps) => {
  const [expand, setExpand] = useState(false)

  return <div>
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Bankruptcies ({bankruptcies.length})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Filed</th>
          <th>Industry</th>
          <th>Filer</th>
          <th>Type</th>
          <th>Intent/Disposition</th>
          <th>Prior Int/Dis</th>
        </tr>
      </thead>
      <tbody>
        {bankruptcies?.map((b, i) => <tr key={i} title={JSON.stringify(b, false, ' ')}>
          <td>{yyyymmdd(b.dateFiled)}</td>
          <td>{b.industryCode}</td>
          <td>{b.filer}</td>
          <td>{b.type}</td>
          <td><CodedDescriptor {...b.currentIntentOrDispositionCode} /></td>
          <td><CodedDescriptor {...b.priorIntentOrDispositionCode} /></td>
        </tr>)}
      </tbody>
    </table> : null}

  </div >
}

type CollectionsProps = {
  collections: EquifaxCollection[]
}

const Collections = ({ collections }: CollectionsProps) => {
  const [expand, setExpand] = useState(false)

  return <div>
    <div className="section-hdr" onClick={() => setExpand(!expand)}
      style={{}}>
      Collections ({collections.length})
      {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Delinquency</th>
          <th>Balance</th>
          <th>Orig Amt</th>
          <th>Customer no</th>
          <th>Classification</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {collections.map((c, i) => <tr key={i} title={JSON.stringify(c, false, ' ')}>
          <td>{yyyymmdd(c.dateOfFirstDelinquency)}</td>
          <td>{c.balance}</td>
          <td>{c.originalAmount}</td>
          <td>{c.customerNumber}</td>
          <td><CodedDescriptor {...c.creditorClassificationCode} /></td>
          <td><CodedDescriptor {...c.statusCode} /></td>
        </tr>)}
      </tbody>
    </table> : null}
  </div>
}

type EvaluationProps = {
  evaluation: CriteriaEvaluationResult
}

const CriteriaCell = ({ spec }: { spec: CriteriaSpec }) => {
  return <td>{
    Object.entries(spec).map(([key, value]) => <div key={key}>
      {key}:&nbsp;
      <b>
        {typeof value.getDay === 'function' ? yyyymmdd(value as Date) : value.toString()}
      </b>
    </div>)
  }
  </td>

}

const Evaluation = ({ evaluation }: EvaluationProps) => {
  const [expand, setExpand] = useState(false)

  const passed = evaluation.byCriteria.filter(r => r.result === 'pass')

  return <div className="eval">
    <div style={{ cursor: 'pointer' }}>
      {passed.length
        ? <div className="badge fs-6 mb-2 text-bg-success" onClick={() => setExpand(!expand)}>
          Approved&nbsp;({passed.length})
          {expand ? <FaChevronDown /> : <FaChevronRight />}
        </div>
        : <div className="badge fs-6 mb-2 text-bg-danger" onClick={() => setExpand(!expand)}>
          Declined&nbsp;
          {expand ? <FaChevronDown /> : <FaChevronRight />}
        </div>}
    </div>
    {expand ? <table className="table">
      <thead>
        <tr>
          <th>Result</th>
          <th>Min</th>
          <th>DTI</th>
          <th>Bankruptcy</th>
          <th>Collections</th>
          <th>Past Due</th>
        </tr>
      </thead>
      <tbody>
        {evaluation.byCriteria.map((res, i) => <Fragment key={i}>
          <tr>
            <td>{res.result === 'pass' ? <FaCircleCheck color='green' /> : <FaCircleXmark color='red' />}</td>
            <td>{res.criteria.minScore}</td>
            <td>{res.criteria.debtToIncome}</td>
            <CriteriaCell spec={res.criteria.bankruptcy} />
            <CriteriaCell spec={res.criteria.collections} />
            <CriteriaCell spec={res.criteria.pastDue} />
          </tr>
          {res.result === 'fail' ? <tr>
            <td colSpan={7}>
              {res.reasons.map((reason, j) => <div key={j}>{reason}</div>)}
            </td>
          </tr> : null}
        </Fragment>)}
      </tbody>
    </table> : null}
  </div>
}

type CreditReportItemProps = {
  report: EquifaxPrequalReport
  item: any
}

export const CreditReportItem = ({ report, item }: CreditReportItemProps) => {
  const [showJson, setShowJson] = useState(false)
  const [privacy, setPrivacy] = useState(true)
  const {
    bankruptcies,
    collections,
    inquiries,
    trades,
    models,
    subjectSocialNum,
    subjectName,
    addresses,
    ...rest
  } = report

  const sharedMonthlyDebt = trades
    .filter(t => t.isSharedWithCoapplicant)
    .reduce((acc, t) => {
      return acc + (t.balance > 0 ? t.scheduledPaymentAmount : 0)
    }, 0)

  const evaluation = item.evaluation

  return <div className="list-group-item credit-report" style={{}}>
    <div className="hdr-cont">
      <div className="me-2 pointer" onClick={() => setPrivacy(!privacy)}>{privacy ? <FaEyeSlash /> : <FaEye />}</div>
      <h5 className="m-0" onClick={() => setShowJson(!showJson)}>
        Prequal: {privacy ? '[Redacted]' : item.customerName}
      </h5>

    </div>
    {item.comboName ? <div className="help">Joint Application With: {privacy ? '[redacted]' : item.comboName}</div> : null}
    <div className="help mb-2">Pulled: {dateAndTime(new Date(item.createdDate))}</div>
    {report.isNoHit() ? <>
      <div className="badge text-bg-secondary">No-Hit</div>
    </> : <>
      <div className="cr-summary">
        <div className="info">
          {showJson ? <pre style={{ fontSize: '0.8em' }}>{JSON.stringify({ ...rest, models }, false, ' ')}</pre> : null}
          {report.models?.map((model, i) => <Score key={i} model={model} />)}

          <table className="table mb-3 cr-highlights">
            <tbody>
              <tr>
                <td>Stated Monthly Income</td>
                <td>{dollars(evaluation?.statedGrossMonthlyIncome || evaluation?.statedIncome)}</td>
              </tr>
              <tr>
                <td>Monthly Debt</td>
                <td>{dollars(report.monthlyDebt || 0, 0)}</td>
              </tr>
              {sharedMonthlyDebt ? <tr>
                <td>Shared Monthly Debt</td>
                <td>{dollars(sharedMonthlyDebt || 0, 0)}
                </td>
              </tr> : null}
              <tr>
                <td>Combined Monthly Debt</td>
                <td>{dollars(report.combinedMonthlyDebt || 0, 0)}</td>
              </tr>
              <tr>
                <td>Current DTI</td>
                <td>{item.evaluation.currentDti?.toFixed(1)}%</td>
              </tr>
              <tr>
                <td>Post DTI</td>
                <td>{item.evaluation.postDti?.toFixed(1)}%</td>
              </tr>
              <tr>
                <td>Estimated Payment</td>
                <td>{dollars(item.evaluation.estimatedPayment)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="late-pay-summary table mb-3 table-bordered">
            <thead>
              <tr className="table-primary">
                <th colSpan={8}>Late Payments</th>
              </tr>
              <tr className="table-secondary">
                <th>&lt;=2</th>
                <th>&lt;=3</th>
                <th>&lt;=4</th>
                <th>&gt;=5</th>
                <th>Collection</th>
                <th>Ch 13</th>
                <th>Repo</th>
                <th>Charge-off</th>
              </tr>
            </thead>
            <tbody>
              {report.latePaymentSummary ? <tr>
                {Object.values(report.latePaymentSummary).map((v, i) => <td key={i}>{v}</td>)}
              </tr> : <tr>
                <td colSpan={8}>Not available</td>
              </tr>}
            </tbody>
          </table>
        </div>
      </div>
      <Evaluation evaluation={item.evaluation} />
      <div className="section-hdr">Inquiries ({inquiries?.length || 0})</div>
      <Bankruptcies bankruptcies={bankruptcies} />
      <Collections collections={collections} />
      <Trades trades={trades} />
    </>}
  </div>
}

