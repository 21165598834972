import { Spinner } from "@fluentui/react"
import { errorToString, IApiKey, Org } from "@oneethos/shared"
import { useState } from "react"
import { FaTrash } from "react-icons/fa"
import { FaRectangleList } from "react-icons/fa6"
import { toast } from "react-toastify"
import api from '../api-client'
import { useApi } from "../hooks"
import { Link } from "./link"
import { NewApiKey } from "./new-api-key"

type ApiKeysProps = {
  org: Org
  onNewKey?: () => void
}

const LoadableLink = ({ onClick, children, title }) => {
  const [loading, setLoading] = useState(false)

  return loading ? <Spinner /> : <Link
    href="#"
    title={title}
    onClick={ev => {
      ev.preventDefault()
      setLoading(true)
      onClick().finally(() => setLoading(false))
    }}>{children}</Link>
}

const ApiKeyRecord = ({ apikey, onArchive }) => {
  const [showUsage, setShowUsage] = useState(false)

  const k = apikey

  return <tr key={k.clientId} className={k.archived ? 'table-secondary' : ''}>
    <td className={k.archived ? 'text-secondary' : ''}>
      <div>{k.clientId}</div>
      <div className="text-muted text-small">Integration: {k.integrationId}</div>
    </td>
    <td>
      {k.usage.tokensIssuedCount ? <div className="row">
        <div className="col" style={{ flex: 1 }}>
          <div>Last usage: {k.usage.recentActivity?.slice(-1)[0].timestamp}</div>
          <div className="text-muted text-small">{k.usage.tokensIssuedCount} total access tokens issued</div>
        </div>
        <div className="col" style={{ flex: 0 }} title="recent usage">
          <FaRectangleList style={{ cursor: 'pointer' }} onClick={() => setShowUsage(!showUsage)} />
        </div>
        {showUsage ? <div className="mt-3">
          <div className="m-2">Recent token grants</div>
          <ul className="list-group">
            {k.usage.recentActivity.map((a, i) => <li className="list-group-item" key={i}>
              <div>{a.email}</div>
              <div className="text-muted text-small">{a.timestamp}</div>
            </li>)}
          </ul>
        </div> : null}
      </div> : <div className="text-muted text-small">none</div>}
    </td>
    <td className={k.archived ? 'text-secondary' : ''}>
      <div>{new Date(k.createdDate).toLocaleString()}</div>
      <div className="text-muted text-small">by {k.createdBy?.email}</div>
    </td>
    <td>
      {k.archived ? <span className="text-secondary">Archived</span> : <LoadableLink title="archive key"
        onClick={() => {
          if (window.confirm('Are you sure you want to archive this key? This will immediately disable API access for any applications using this key.')) {
            return api.delete(`/api-keys/${k.clientId}`).then(() => {
              toast.success('API Key has been successfully archived')
              onArchive()
            }).catch(ex => {
              toast.error(errorToString(ex))
            })
          } else {
            return Promise.resolve()
          }
        }}><FaTrash /></LoadableLink>}
    </td>
  </tr>
}

export const ApiKeys = ({ org }: ApiKeysProps) => {
  const [newKey, setNewKey] = useState<IApiKey>(null)
  const [refetch, setRefetch] = useState(0)
  const { data: apiKeys, fetching, error } = useApi<IApiKey[]>(`/api-keys?orgId=${org._id}`, [refetch])

  return <div>
    <h3>API Keys</h3>
    <p>
      API keys are used to authenticate your organization's integrations with the OneEthos API.
      You can create as many keys as you need, but each key should be kept secret and
      only used by the application for which it was created. Integrators
      may also create keys on behalf of your organization if OneEthos has enabled the integration
      for your organization.
    </p>
    {fetching ? <Spinner /> : null}
    {error ? <div className="alert alert-danger">{errorToString(error)}</div> : null}
    {apiKeys?.length === 0 && <div className="alert alert-secondary">
      Either no API keys have been created or they may not be visible to you
    </div>}
    {apiKeys?.length > 0 ? <table className="table">
      <tbody>
        <tr>
          <th>Client Id</th>
          <th>Usage</th>
          <th>Created</th>
          <th></th>
        </tr>
        {apiKeys.map(k => <ApiKeyRecord
          key={k.clientId}
          apikey={k}
          onArchive={() => setRefetch(r => r + 1)}
        />)}
      </tbody>
    </table> : null}
    {newKey ? <div className="alert alert-info">
      <p><span className="fw-bold">IMPORTANT:</span> This is the only time you will see the secret for these credentials.
        Please ensure it is stored securely. We cannot retrieve your secret if you lose it,
        you will have to generate a new Client Id + Secret.</p>
      <div className="form-group">
        <label>Client Id</label>
        <input className="form-control" type="text" value={newKey.clientId} readOnly />
      </div>
      <div className="form-group">
        <label>Client Secret</label>
        <input className="form-control" type="text" value={newKey.secret} readOnly />
      </div>

      <button
        onClick={() => {
          setNewKey(null)
          setRefetch(r => r + 1)
        }}
        className="btn btn-primary"
      >I have saved my secret credential</button>
    </div> : <NewApiKey onNewKey={setNewKey} org={org} />}
  </div>
}

export default ApiKeys