import React from 'react'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'

type SingleSelectProps = {
  options: string[]
  value: string | null
  onChange: (value: string | null) => void
  wrapStyle?: React.CSSProperties
}

export const SingleSelect = ({ options, value, onChange, wrapStyle }: SingleSelectProps ) => {
  return (
    <div className="multi-select list-group" style={{ minWidth: '20em', ...wrapStyle }}>
      {options.map((type) => {
        const selected = value === type
        return (
          <div
            key={type}
            className={['list-group-item p-2'].join(' ')}
            onClick={() => {
              onChange(selected ? null : type)
            }}
          >
            <div className="chk-cont me-2">
              {selected ? (
                <RiCheckboxCircleFill color="green" size={20} />
              ) : (
                <RiCheckboxBlankCircleLine color="#aaa" size={20} />
              )}
            </div>
            <span>{type}</span>
          </div>
        )
      })}
    </div>
  )
}